import type { Category } from './categories';
import { defineMessage, MessageDescriptor } from 'react-intl';
import hitch from '../../hitch.json';
export type Status = 'supported' | 'pipeline' | 'planning';

export const StatusTranslations: Record<Status, MessageDescriptor> = {
  supported: defineMessage<MessageDescriptor>({
    id: 'hitch-status-supported',
    defaultMessage: 'supported',
  }),
  pipeline: defineMessage<MessageDescriptor>({
    id: 'hitch-status-pipeline',
    defaultMessage: 'pipeline',
  }),
  planning: defineMessage<MessageDescriptor>({
    id: 'hitch-status-planning',
    defaultMessage: 'planning',
  }),
};

export type Hitch = {
  id: string;
  name: string;
  icon: string;
  category: Category['id'][];
  status: Status;
};

const hitches = hitch as Hitch[];

export const Hitches: Record<Hitch['id'], Hitch> = hitches.reduce((prevValue, currValue: Hitch) => {
  return {
    ...prevValue,
    [currValue.id]: currValue,
  };
}, {});

export default hitches;
